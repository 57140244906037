import { useState } from 'react'
import Validator from 'validatorjs'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import PlanPicker from './components/PlanPicker'

export default function WaitlistForm({
  plans,
  onSubmit,
  isDisabled,
  isLoading,
  ...props
}) {
  const [values, setValues] = useState({
    address: '',
    email: '',
    plan: '',
  })
  const [errors, setErrors] = useState({})

  const handleSubmit = async event => {
    event.preventDefault()

    const rules = {
      address: 'required',
      email: 'required|email',
      plan: 'required',
    }

    const messages = {
      'required.address': 'Address is required.',
      'required.email': 'Email is required.',
      'email.email': 'Not a valid email address.',
      'required.plan': 'Please select a plan.',
    }

    const validator = new Validator(values, rules, messages)

    if (validator.fails()) {
      setErrors(validator.errors.all())
      return
    }

    setErrors({})
    onSubmit?.(values)
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.id]: event.target.value,
    })
  }

  const selectedPlan = plans.find(plan => values.plan === plan.id)

  return (
    <form onSubmit={handleSubmit} {...props}>
      <VStack spacing={8}>
        <Heading
          bgGradient='linear(to-br, #2776E9, #DC87FF)'
          bgClip='text'
          textAlign='center'
        >
          Join the Waitlist
        </Heading>

        <Text>
          Join the waitlist and invite your neighbors to bring competitive
          Internet to your neighborhood.
        </Text>

        <FormControl
          id='address'
          isDisabled={isDisabled}
          isInvalid={errors.address}
          isRequired
        >
          <FormLabel>Service Address</FormLabel>
          <Input
            type='address'
            placeholder='123 Street, Sandy, UT 84070'
            onChange={handleChange}
            value={values.address}
          />
          {errors.address?.map((error, index) => (
            <FormErrorMessage key={index}>{error}</FormErrorMessage>
          ))}
        </FormControl>

        <FormControl
          id='email'
          isDisabled={isDisabled}
          isInvalid={errors.email}
          isRequired
        >
          <FormLabel>Email</FormLabel>
          <Input
            type='email'
            placeholder='email@domain.com'
            onChange={handleChange}
            value={values.email}
          />
          {errors.email?.map((error, index) => (
            <FormErrorMessage key={index}>{error}</FormErrorMessage>
          ))}
        </FormControl>

        <FormControl
          id='plan'
          isDisabled={isDisabled}
          isInvalid={errors.plan}
          isRequired
        >
          <FormLabel>Desired Internet Speed</FormLabel>
          <PlanPicker
            plans={plans}
            onChange={plan =>
              setValues({
                ...values,
                plan,
              })
            }
            value={values.plan}
          />
          {errors.plan?.map((error, index) => (
            <FormErrorMessage key={index}>{error}</FormErrorMessage>
          ))}
        </FormControl>

        {selectedPlan && (
          <VStack textAlign='center'>
            <Text fontWeight='bold'>{selectedPlan.description}</Text>
            <Text>
              Estimated cost: ${selectedPlan.cost} / month + $100 installation
            </Text>
          </VStack>
        )}

        <Box>
          <VStack>
            <Text fontSize='sm'>All plans include unlimited data.</Text>
            <Text fontSize='sm'>Speeds include full upload and download.</Text>
          </VStack>
        </Box>

        <Box>
          <Button
            type='submit'
            isDisabled={isDisabled}
            isLoading={isLoading}
            size='lg'
            bg='blue.600'
            color='white'
            _active={{
              bgGradient: 'linear(to-bl, #2776E9, #DC87FF)',
            }}
            _hover={{
              bgGradient: 'linear(to-bl, #2776E9, #DC87FF)',
            }}
          >
            Join the Waitlist
          </Button>
        </Box>
      </VStack>
    </form>
  )
}
