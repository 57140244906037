import { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import WaitlistForm from './components/WaitlistForm'
import WaitlistSuccess from './components/WaitlistSuccess'

const WAITLIST_QUERY = gql`
  query {
    plans {
      id
      speed {
        amount
        measure
      }
      cost
      description
    }
  }
`

const JOIN_WAITLIST_MUTATION = gql`
  mutation JoinWaitlist(
    $address: String!
    $email: String!
    $plan: String!
    $referredBy: String
  ) {
    joinWaitlist(
      address: $address
      email: $email
      plan: $plan
      referredBy: $referredBy
    ) {
      address
      email
      plan
      shareableReferralCode
      referredBy
    }
  }
`

export default function Waitlist() {
  const [waitlistReservation, setWaitlistReservation] = useState()
  const { data, loading } = useQuery(WAITLIST_QUERY)
  const [joinWaitlist, { loading: isJoiningTheWaitlist }] = useMutation(
    JOIN_WAITLIST_MUTATION,
  )

  if (loading || !data?.plans) {
    return null
  }

  if (waitlistReservation) {
    return <WaitlistSuccess plans={data.plans} {...waitlistReservation} />
  }

  return (
    <WaitlistForm
      plans={data.plans}
      disabled={isJoiningTheWaitlist}
      onSubmit={async values => {
        const queryString = new URLSearchParams(window.location.search)
        const referredBy = queryString.get('code')

        const { data } = await joinWaitlist({
          variables: {
            ...values,
            referredBy,
          },
        })

        setWaitlistReservation(data.joinWaitlist)
      }}
    />
  )
}
