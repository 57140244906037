import {
  Box,
  Text,
  HStack,
  VStack,
  useRadioGroup,
  useRadio,
  useColorModeValue,
} from '@chakra-ui/react'

function RadioBox({ children, ...props }) {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const hoverBorderColor = useColorModeValue('gray.300', 'whiteAlpha.400')

  const inputProps = getInputProps()
  const checkboxProps = getCheckboxProps()

  return (
    <Box as='label' flex={1}>
      <input {...inputProps} />
      <Box
        {...checkboxProps}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        _checked={{
          bg: 'blue.600',
          color: 'white',
          borderColor: 'blue.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        _hover={{
          borderColor: hoverBorderColor,
        }}
        boxSizing='content-box'
        children={children}
      />
    </Box>
  )
}

export default function PlanPicker({ plans, onChange, defaultValue }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'plan',
    defaultValue,
    onChange,
  })

  const radioGroupProps = getRootProps()

  return (
    <HStack {...radioGroupProps}>
      {plans.map(plan => {
        const radioProps = getRadioProps({ value: plan.id })

        return (
          <RadioBox key={plan.id} {...radioProps}>
            <VStack spacing={0} py={2}>
              <Text fontSize='2xl' fontWeight='bold'>
                {plan.speed.amount}
              </Text>
              <Text>{plan.speed.measure}</Text>
            </VStack>
          </RadioBox>
        )
      })}
    </HStack>
  )
}
