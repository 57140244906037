import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Text,
  VStack,
  useClipboard,
  useColorModeValue,
} from '@chakra-ui/react'

export default function WaitlistSuccess({
  address,
  email,
  plan,
  shareableReferralCode,
  plans,
}) {
  const referralLink = `https://skyfiber.network/?code=${shareableReferralCode}`
  const { hasCopied, onCopy } = useClipboard(referralLink)
  const referralBackgroundColor = useColorModeValue(
    'gray.200',
    'whiteAlpha.200',
  )

  const selectedPlan = plans.find(element => element.id === plan)

  return (
    <VStack spacing={8} w='full' maxW='xl'>
      <Heading
        bgGradient='linear(to-b, #2776E9, #DC87FF)'
        bgClip='text'
        textAlign='center'
      >
        You have joined the waitlist!
      </Heading>

      <VStack>
        <Text>{address}</Text>
        <Text>{email}</Text>
        <Box
          borderWidth='1px'
          borderColor='blue.600'
          borderRadius='md'
          bg='blue.600'
          color='white'
          px={8}
          py={4}
        >
          <VStack spacing={0}>
            <Text fontSize='2xl' fontWeight='bold'>
              {selectedPlan.speed.amount}
            </Text>
            <Text>{selectedPlan.speed.measure}</Text>
          </VStack>
        </Box>
      </VStack>

      <VStack
        borderRadius='md'
        bg={referralBackgroundColor}
        px={8}
        py={4}
        w='full'
      >
        <Text textAlign='center' fontSize='sm'>
          Refer your friends and neighbors and you both <br />
          will earn a free month of service.
        </Text>
        <HStack w='full'>
          <Input value={referralLink} isReadOnly />
          <Button variant='link' onClick={onCopy}>
            {hasCopied ? 'Copied' : 'Copy'}
          </Button>
        </HStack>
      </VStack>
    </VStack>
  )
}
