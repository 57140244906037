import { Container, Image, VStack, useColorModeValue } from '@chakra-ui/react'
import Waitlist from 'pages/Waitlist'
import logoLight from './images/logo/light.svg'
import logoDark from './images/logo/dark.svg'
import logoIconic from './images/logo/iconic.svg'

export default function App() {
  const logo = useColorModeValue(logoLight, logoDark)

  return (
    <Container padding={8} maxW='container.lg'>
      <VStack spacing={8}>
        <VStack spacing={16}>
          <Image src={logoIconic} alt='Sky Fiber' h={48} />
          <Image src={logo} alt='Sky Fiber' />
        </VStack>
        <Waitlist />
      </VStack>
    </Container>
  )
}
